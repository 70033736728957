<template>
  <div class=''>

    <el-dialog :visible="dialogVisible" width="70%" @close='close'>
      <div class="flex">
        <img :src="info.pcPic" class="w626 h700 mr60" style="object-fit: cover;">
        <div class="">
          <div class="fs32 fw-600 color0">{{info.memberName}} {{info.memberPosition}}</div>
          <div class="mt40 fs18 color9D lh45">
            {{info.memberCont}}
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

  <script>
export default {
  name: 'userInfoDialog',
  props: {
    dialogVisible: {
      default: false
    },
    info: {}
  },
  data () {
    return {
    };
  },

  components: {},

  created () { },

  mounted () { },

  methods: {
    close () {
      this.$emit('update:dialogVisible', false)
    }
  }
}
  </script>
  <style lang='scss' scoped>
</style>
