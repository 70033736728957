<template>
  <div class='container pb60'>
    <aboutUsBorder :title="title" :content='content' />
    <div class="mt120 flex flex-wrap ">
      <div class="flex mr100 box_item mb60" style="background-color: #F9F9F9;" v-for="(item,i) in list" :key="i"
        @click="btnInfo(item)">
        <div class="w410 h450 flex row-center row-column col-center">
          <div class="color0 fs46 fw-600">{{item.expertName}}</div>
          <div class="color9D fs18 mt60">{{item.expertPosition}}</div>
        </div>
        <img :src="item.pcPic" class="w400 h450" />
      </div>
    </div>
    <userInfoDialog :info='info' :dialogVisible.sync="dialogVisible"></userInfoDialog>
  </div>
</template>

<script>
import { getExpert } from '@/api'
import aboutUsBorder from '@/components/aboutUsBorder.vue'
import userInfoDialog from '@/components/userInfoDialog.vue'
export default {
  data () {
    return {
      title: '专家顾问',
      content: "内容内容内容内容内容内容内容内容内容内容",
      list: [],
      info: {},
      dialogVisible: false
    };
  },

  components: { aboutUsBorder, userInfoDialog },

  created () {
    this.$store.commit('editAboutRouterName', this.$route.meta.title)
    //获取专家列表
    this.getExpert()
  },

  mounted () { },

  methods: {
    //获取专家列表
    async getExpert () {
      const res = await getExpert()
      res.data.forEach(item => {
        item.memberName = item.expertName
        item.memberPosition = item.expertPosition
        item.memberCont = item.expertCont
      });
      this.list = res.data
    },
    btnInfo (item) {
      this.info = item
      this.dialogVisible = true
    }
  }
}
</script>
<style lang='scss' scoped>
.box_item:nth-child(even) {
  margin-right: 0 !important;
}
</style>
