<template>
  <div class="header_box mr200 ml200 mt60 pl30 pr30 pb45">
  	<div class="fs32 fw-600 w384 text-center team_title" style="margin: 0 auto;">{{title}}</div>
  	<div class="text-center flex col-center row-center h-max color9D fs24 mt72">{{content}}</div>
  </div>
</template>

<script>
export default {
	props:{
		title:{},
		content:{}
	},
  data () {
    return {
    };
  },

  components: {},

  created() {},

  mounted() {},

  methods: {}
}

</script>
<style lang='scss' scoped>
	.header_box {
		border: 4px solid #F7B500;
	
	}
	
	.team_title {
		background-color: #fff;
		margin-top: -32px !important;
	}
	
</style>